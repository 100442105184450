import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '@context';
import { useApolloClient } from '@apollo/client';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { navigate, PageProps } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import { LayoutMissionDesktop, LayoutMissionMobile } from '@components';
import { useAuth } from '@hooks';
import { MB1Step1, MB1Step2, MB1Step3 } from '@components';
import { Route } from '@interfaces';

const LoansDebtIndex: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const [page, setPage] = useState(1);
  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.LoansDebtCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.LoansDebtCategory.m1_2.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);
  return (
    <>
      <SEO />
      {isMobile ? (
        <>
          {page === 1 ? (
            <LayoutMissionDesktop
              title={'Cotizar un crédito: qué deberías saber'}
              number={1}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.creditsDebts)}
              // numberAdjust={0.3125}
            >
              <MB1Step1 setPage={setPage} />
            </LayoutMissionDesktop>
          ) : page === 2 ? (
            <LayoutMissionDesktop
              title={'Cotizar un crédito: qué deberías saber'}
              number={1}
              numberAdjust={0.3125}
              secondLayoutForShortcuts
              setPage={setPage}
              pageNumber={1}
              confettiFire={confettiFire}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <MB1Step2 setPage={setPage} />
            </LayoutMissionDesktop>
          ) : (
            page === 3 && (
              <LayoutMissionDesktop
                title={'Cotizar un crédito: qué deberías saber'}
                number={1}
                numberAdjust={0.3125}
                secondLayoutForShortcuts
                setPage={setPage}
                pageNumber={2}
                confettiFire={confettiFire}
                onHouseClick={() => navigate(Route.creditsDebts)}
              >
                <MB1Step3 setPage={setPage} handleClick={handleClick} />
              </LayoutMissionDesktop>
            )
          )}
        </>
      ) : (
        <>
          {page === 1 ? (
            <LayoutMissionMobile
              title={'Cotizar un crédito: qué deberías saber'}
              number={1}
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <MB1Step1 setPage={setPage} />
            </LayoutMissionMobile>
          ) : page === 2 ? (
            <LayoutMissionMobile
              title={'Cotizar un crédito: qué deberías saber'}
              number={1}
              secondLayoutForShortcuts
              onHouseClick={() => navigate(Route.creditsDebts)}
            >
              <MB1Step2 setPage={setPage} />
            </LayoutMissionMobile>
          ) : (
            page === 3 && (
              <LayoutMissionMobile
                title={'Cotizar un crédito: qué deberías saber'}
                number={1}
                secondLayoutForShortcuts
                confettiFire={confettiFire}
                onHouseClick={() => navigate(Route.creditsDebts)}
              >
                <MB1Step3 setPage={setPage} handleClick={handleClick} />
              </LayoutMissionMobile>
            )
          )}
        </>
      )}
    </>
  );
};

export default LoansDebtIndex;
